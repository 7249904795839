import React from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image"

import Card from "src/components/Card";
import Column from "src/components/Column";
import CVRTLogo from "src/containers/CVRTLogo";

import css from "./index.module.scss";


const ImageCVRTRow = props => {
  return (
    <Column className={css.imageCVRTRow} direction="cc">
      <Column className={css.cardCol} direction="cc">
        <Card className={css.cardImg}>
          <Img className={css.img} fluid={props.image}/>
        </Card>
      </Column>
      <CVRTLogo/>
    </Column>
  );
};

ImageCVRTRow.propTypes = {
  image: PropTypes.object.isRequired
};

export default ImageCVRTRow;
