import React from "react";
import PropTypes from "prop-types";

import css from "./index.module.scss";

const Disabled = props => {
  
  const disabled = () => {
    return (
      <span title={"Element is currently disabled"}>
        <div className={[css.disabled, props.className].join(" ")} style={props.style}>
          {props.children}
        </div>
      </span>
    )
  };
  
  return props.disabled ? disabled() : props.children;
};

Disabled.defaultProps = {
  className: "",
  disabled: false
};

Disabled.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  style: PropTypes.object
};

export default Disabled;