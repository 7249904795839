import React from 'react';
import PropTypes from 'prop-types';

import Disabled from 'src/components/Disabled';

import css from './index.module.scss';


const Button = props => {
  return (
      <Disabled disabled={props.disabled}>
        <div className={[css[props.type], props.className].join(' ')} onClick={props.onClick} style={props.style}>
          {props.children}
        </div>
      </Disabled>
  );
};

Button.defaultProps = {
  className: '',
  disabled: false,
  type: 'default'
};

Button.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  style: PropTypes.object,
  type: PropTypes.oneOf(['default', 'outlined'])
};

export default Button;
