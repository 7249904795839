import React from "react";
import Loader from 'react-loader-spinner'

import Column from "src/components/Column";
import Header from "../../components/Header";
import ImageCVRTRow from "src/containers/ImageCVRTRow"
import {withSEO} from "src/hoc/SEO";
import { pages } from "src/config";
import {graphql, Link, StaticQuery} from "gatsby";

import css from "./index.module.scss";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Row from "../../components/Row";
import Img from "gatsby-image";


class ContactPage extends React.Component {
  state = this.getInitialState();
  
  getInitialState() {
    return {
      enquiry: "",
      email: "",
      errors: {
        email: false,
        name: false,
        enquiry: false
      },
      honeyPot: '',
      name: "",
      submitted: false,
      submitting: false,
    };
  }
  
  handleOptionChange(event) {
    const {target: {name, value}} = event;
    
    if (
      (name === 'email' || name === 'enquiry') ||
      ((name === 'name') && /^[a-z'\- ]*$/i.test(value)) ||
      (name === 'honeyPot')
    ) {
      this.setState({[name]: value});
      this.handleErrorCheck(event);
    }
  };
  
  handleErrorCheck(event) {
    const {target: {name, value}} = event;
    if(name !== 'honeypot') {
      this.errorCheck(name, value);
    }
  };
  
  errorCheck(key, value) {
    const {errors} = this.state;
    
    if (
      (key === 'email' && /\S+@\S+\.\S+/.test(value)) ||
      ((key === 'name' || key === 'enquiry') && value.length > 0)
    ) {
      this.setState({errors: {...errors, [key]: false}});
    } else {
      this.setState({errors: {...errors, [key]: true}});
      return true;
    }
    return false
  }
  
  preFormSubmissionErrorCheckHasError() {
    const errorsUpdate = {};
    
    if(this.state.honeyPot !== "") {
      return true;
    }
  
    if(!/\S+@\S+\.\S+/.test(this.state.email)) {
      errorsUpdate["email"] = true;
    }
    if(this.state.name.length < 1) {
      errorsUpdate["name"] = true;
    }
    if(this.state.enquiry.length < 1) {
      errorsUpdate["enquiry"] = true;
    }
  
    this.setState({errors: {...this.state.errors, ...errorsUpdate}});
    return Object.values(errorsUpdate).includes(true);
  }
  
  handleFormSubmission(event) {
    event.preventDefault();
    if(!this.preFormSubmissionErrorCheckHasError()) {
      this.setState({submitting: true});
      const {email, name, enquiry} = this.state;
      fetch('https://3ujq3brflc.execute-api.eu-west-1.amazonaws.com/prod', {
        method: 'post',
        body: JSON.stringify({
          'name': name,
          'email': email,
          'enquiry': enquiry
        })
      }).then(() => {
        this.setState({submitting: false, submitted: true});
        setTimeout(() => this.setState({...this.getInitialState(), submitted: false}), 3000);
      });
    }
  }
  
  render () {
    const {errors, email, enquiry, honeyPot, name, submitted, submitting} = this.state;
    
    return (
      <main>
        <StaticQuery
          query={graphql`
          query {
            contact: file(relativePath: { eq: "images/contact/contact.jpg" }) {
              childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            logo: file(relativePath: { eq: "images/test-centre/logo.png" }) {
              childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        `}
          render={data => (
              <Column direction="cc">
                <Link to='/' className={css.logoImg}>
                  <Img fluid={data.logo.childImageSharp.fluid}/>
                </Link>
                <ImageCVRTRow image={data.contact.childImageSharp.fluid}/>
              </Column>
          )}
        />
      
        <Column className={css.contact} direction="cc">
          <Header primaryText="Contact Us"/>
          <form onSubmit={this.handleFormSubmission.bind(this)}>
          
            <Column className={css.section}>
              <div className={css.block}>
                <label>Name:</label>
                <input
                  name="name"
                  onBlur={this.handleErrorCheck.bind(this)}
                  onChange={this.handleOptionChange.bind(this)}
                  type="text"
                  value={name}
                />
              </div>
              <p className={[css.error, errors.name ? css.visible : css.hidden].join(" ")}>A valid name is required</p>
            </Column>
  
            <Column className={css.section}>
              <div className={css.block}>
                <label>Email Address:</label>
                <input
                  name="email"
                  onBlur={this.handleErrorCheck.bind(this)}
                  onChange={this.handleOptionChange.bind(this)}
                  type="email"
                  value={email}
                />
              </div>
              <p className={[css.error, errors.email ? css.visible : css.hidden].join(" ")}>A valid email address is required</p>
            </Column>
  
            <Column className={css.section}>
              <div className={css.block}>
                <label className={css.textareaLabel}>Enquiry:</label>
                <textarea
                  name="enquiry"
                  onBlur={this.handleErrorCheck.bind(this)}
                  onChange={this.handleOptionChange.bind(this)}
                  value={enquiry}
                />
              </div>
              <p className={[css.error, errors.enquiry ? css.visible : css.hidden].join(" ")}>An enquiry message is required</p>
            </Column>
  
            <input id="honeyPot" name="honeyPot" className={css.honeyPot} onChange={this.handleOptionChange.bind(this)} value={honeyPot}/>
  
  
            {submitting ?
              <Row direction='cc'>
                Submitting enquiry...
                <Loader type='Circles' color='#f26532' height={30} />
              </Row>
              : submitted ?
                <Row direction='cc'>
                  Enquiry submitted successfully <span className={css.checkmark}>&#10004;</span>
                </Row>
                :
                <Row direction='cc'>
                  <button className={css.submitButton} type="submit">Submit Enquiry</button>
                </Row>
            }
          </form>
        </Column>
    
      </main>
    );
  }
}

export default withSEO({
  title: pages.faqs.title
})(ContactPage);
