import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image"

import Button from "src/components/Button";
import Column from "src/components/Column";

import { config } from "src/config";
import css from "./index.module.scss";


const CVRTLogo = ({hidePhoneNumber}) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          cvrt: file(relativePath: { eq: "images/test-centre/cvrt-logo.png" }) {
            childImageSharp {
              fluid(maxWidth: 300) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      `}
      render={data => (
        <Column className={css.cvrtLogo} direction="cc">
          <Img className={css.cvrtImg} fluid={data.cvrt.childImageSharp.fluid}/>
          <Button className={css.bookTestBtn} onClick={config.bookTestBtn.onClick}>
            {config.bookTestBtn.text}
          </Button>
          <a style={hidePhoneNumber ? {display: 'none'} : {}} href="tel:+353-22-21614">022 21614</a>
          <hr></hr>
          <h10>Opening Hours:</h10>
          <h10>Monday to Friday 8.30am - 5.30pm</h10>
          <h10>Saturdays by Appointment</h10>
          
        </Column>
      )}
    />
  );
};


CVRTLogo.defaultProps = {
  hidePhoneNumber: false
};


CVRTLogo.propTypes = {
  hidePhoneNumber: PropTypes.bool
};

export default CVRTLogo;